<template>
  <div id="chart">
    <header>
      <div class="today">{{ today }}</div>
      <h1>Today's top 25 expiring internet artists</h1>
      <p class="description">
  
        A daily updated chart list of the most influential internet artists based on the expiring date of their website. <br>
        Or just a representation of the state of <strong>hypercompetition</strong>  and <strong>anxiety</strong>   of contemporary artists inside (and outside) of the Internet.
        <br>        
        <strong>Guido Segni</strong> , 2013 (updated 2020)
      </p>
    </header>
    

    <ol class="expiring-artists">
      <li v-for="(artist, index) in top25" :key="index" :class="'rank-'+(index+1)">
        <strong>
          <a :href="'http://'+artist.web" target="_blank">
            {{ artist.name }}
          </a>
        </strong>
         <br>
         <span :title="artist.expiring_date">
          expiring {{ artist.expiring_in }}
         </span>

      </li>
    </ol>

  </div>
</template>

<script>
import moment from 'moment';
import artists from './data/artists.json'
import _ from 'lodash';

export default {
  name: 'App',
  components: {
    
  },

  created() {

    //  https://www.whoisxmlapi.com/whoisserver/WhoisService?apiKey=at_x2afHCcT09WyGfXuS9TpOhkeFzyZ6&domainName=leggerostudio.com&outputFormat=json

   /*  this.$http.get('https://www.whoisxmlapi.com/whoisserver/WhoisService?apiKey=at_x2afHCcT09WyGfXuS9TpOhkeFzyZ6&domainName=leggerostudio.com&outputFormat=json')
        .then((resp) => {

          var expiring = moment(resp.data.WhoisRecord.expiresDate);
          console.log("DATA", expiring.format('DD-MM-YYYY'), expiring.fromNow(), expiring.diff(moment(),'days'), artists)

        }) */
        artists.forEach((artist) => {
          var expiring_date;


          if(artist.whois && artist.whois.expiresDate) {
            expiring_date = artist.whois.expiresDate
          } else if (artist.whois && artist.whois.registryData && artist.whois.registryData.expiresDate) {
            expiring_date = artist.whois.registryData.expiresDate
          } else expiring_date = null;
          //console.log(artist.web, " > ",expiring_date);
          //if (artist.web == 'marisaolson.com') console.log(artist.whois)
          
          if(!expiring_date) console.log(artist.web, expiring_date)
          
          var expiring = moment(expiring_date);

          this.artists.push({
            name: artist.name,
            web: artist.web,
            expiring_date:expiring.format('DD/MM/YYYY'),
            expiring_in:expiring.fromNow(),
            diff : expiring.diff(moment(),'days')
          });


        })




  },

  data() {

    return {

      artists:[],
      today: moment().format('MMM, DD YYYY')

    }
    
  },

  computed: {

    top25() {

      var top25 = this.artists.filter((artist) => {
        if (!isNaN(artist.diff) && artist.diff > 0) return artist;
      })




      return _.sortBy(top25,['diff'], ['asc']).slice(0,25);

    }

  }

}
</script>

<style lang="scss">

body {
  font-family: Crimson Text,serif;
  font-size: 24px;
  padding: 1rem 2rem ;



}


#chart {
  width: 60%;

  @media screen and (max-width:1140px) {
    width:100%;
  }

}

h1 {
  font-size: 32px;
  margin: 0;
  margin-bottom: 8px;
}



.today{
  font-size: 18px;
  margin-bottom: 6px;
  color: #999
}

.description {
  font-size: 18px;
  margin: 0;
  color: #333;

}

.expiring-artists {
  //list-style-type:none;
  padding: 0;
  margin-left: 1.25rem;
  columns: 3;
  column-gap: 5%;

  @media screen and (max-width:640px) {
    columns: 1;
  }

  @media screen and(min-width: 641px) and (max-width:768px) {
      columns: 2;
  }


  @media screen and (min-width:1140) and (max-width:1440px) {
      font-size: 16px!important;
  }



li {
  break-inside: avoid;
}


}

@for $i from 0 through 24 {

  .rank-#{$i+1} {
    opacity: (10+(1 + ($i*4)))/100
  }
  
}
</style>
